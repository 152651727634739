import React, { createContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

export interface LinkMetaData {
  sourcePlatform: string;
  destinationPlatform?: string;
  resourceType: string;
  resourceId: string;
}

export interface MultiPlatformBadgeState {
  [key: string]: {
    hideBadge: boolean,
    link: string
  }
}

export interface PlatformLinks {
  [key: string]: {
    link: string,
    uri: string
  }
}

// Define the context type
interface DataContextType {
  inputUrl: string;
  setInputUrl: Dispatch<SetStateAction<string>>;
  hideWelcomeElements: boolean;
  setHideWelcomeElements: Dispatch<SetStateAction<boolean>>;
  sourcePlatform: string;
  setSourcePlatform: Dispatch<SetStateAction<string>>;
  disableFetch: boolean;
  setDisableFetch: Dispatch<SetStateAction<boolean>>;
  linkMetaData: LinkMetaData;
  setLinkMetaData: Dispatch<SetStateAction<LinkMetaData>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  albumColors: string[];
  setAlbumColors: Dispatch<SetStateAction<string[]>>;
  multiPlatformState: MultiPlatformBadgeState;
  setMultiPlatformState: Dispatch<SetStateAction<MultiPlatformBadgeState>>;
}

// Create the context
const DataContext = createContext<DataContextType | undefined>(undefined);

// Create the provider component
const DataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [inputUrl, setInputUrl] = useState<string>("");
  const [sourcePlatform, setSourcePlatform] = useState<string>("");
  const [hideWelcomeElements, setHideWelcomeElements] = useState<boolean>(true);
  const [disableFetch, setDisableFetch] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [albumColors, setAlbumColors] = useState<string[]>([]);
  const [linkMetaData, setLinkMetaData] = useState<LinkMetaData>({sourcePlatform: '', destinationPlatform: '', resourceType: '', resourceId: ''});
  const [multiPlatformState, setMultiPlatformState] = useState<MultiPlatformBadgeState>({});


  return (
    <DataContext.Provider value={{ inputUrl, setInputUrl, hideWelcomeElements, setHideWelcomeElements, sourcePlatform, setSourcePlatform , setDisableFetch, disableFetch, linkMetaData, setLinkMetaData, multiPlatformState, setMultiPlatformState, loading, setLoading, albumColors, setAlbumColors}}>
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };

import { Text, IconButton, Box, HStack, Icon, Spacer } from "@chakra-ui/react";
import { useContext } from "react";
import { DataContext } from "./DataContext";
import { ExternalLinkIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { BeatLoader } from 'react-spinners';

interface PlatformBadgeProps {
  id: string
  disabled?: boolean
  backgroundColor?: string;
  iconColor?: string;
  iconBgColor?: string;
  textColor?: string;
  platformIcon?: React.ElementType;
  platformName?: string;
}

const PlatformBadge: React.FC<PlatformBadgeProps> = ({ id, disabled, backgroundColor, iconColor, iconBgColor, textColor, platformIcon, platformName}) => {

  //const toast = useToast();
  const {inputUrl, linkMetaData, multiPlatformState, loading} = useContext(DataContext) || {};

  let destinationPlatform = "";
  if (platformName) {
    destinationPlatform = platformName.toLowerCase().split(" ")[0];
  }

  if (destinationPlatform === linkMetaData?.sourcePlatform)
    return null;

  /*
  const getMultiPlatformState = () => {
    if (multiPlatformState) {
      return multiPlatformState[destinationPlatform]?.hideBadge;
    }
  }
  */
  

  /*
  const handleBadgeClickOFF = () => {
    //
    /*
    if (getMultiPlatformState() || !inputUrl)
      return null;

    let errorTitle = '';
    linkMetaData && (linkMetaData.destinationPlatform = destinationPlatform);
    
    const fetchData = async () => {
      if (!multiPlatformState)
        throw new Error("Multi platform state is empty");

      //fake fetch data, to display conversion toast
      //
      
      // Function to create a delay

      if (multiPlatformState[destinationPlatform]?.link) {
        await new Promise(resolve => setTimeout(resolve, 500));
        multiPlatformState[destinationPlatform].hideBadge = false;

        /*
        setMultiPlatformState && setMultiPlatformState(prevState => ({
          ...prevState,
          multiPlatformState[destinationPlatform]
        }))

        setMultiPlatformState && setMultiPlatformState((prevState) => ({
          ...prevState,
          [destinationPlatform]: {
            ...prevState[destinationPlatform],
            hideButtons: false,
          },
        }));


      }

    toast.promise(fetchData(), {
      success: {
        title: `Converted!`,
        description: 'Ready to Share or Open',
        position: 'top', 
        isClosable: true,
        duration: 5000,
      },
      error: {
        title: errorTitle ? errorTitle : 'Unable to Convert',
        duration: 5000,
        isClosable: true,
        position: 'top' 
      },
      loading: {
        title: 'Converting Your Link...',
        position: 'top' 
      },
    });
  }
  */

  const ButtonHandler = async ( action:string ) => {
    //If multi platform state not set, do nothing
    if (!multiPlatformState)
      return null;
    switch (action) {
      case 'copy':
          console.log()
          await navigator.share({
            url: multiPlatformState[destinationPlatform]?.link
          })
          .catch(() => {
            //
          })
        break;
      case 'play':
        window.location.href = multiPlatformState[destinationPlatform]?.link;
        /*
        switch(destinationPlatform) {
          case 'spotify':
            window.location.href = responseCache?.[destinationPlatform].uri ?? "";
            break;
          case 'apple':
            let urlWithoutProto = `${encodeURI(responseCache?.[destinationPlatform].link?? '')}`.replace(/^https?:\/\//, '');
            //
            window.location.href = `music://${urlWithoutProto}`;
            break;
          case 'tidal':
            //Implement tidal deep link here...
            //let urlWithoutProto = `${encodeURI(responseCache?.[destinationPlatform].link?? '')}`.replace(/^https?:\/\//, '');
            ////
            //window.location.href = `music://${urlWithoutProto}`;
            break;
          default:
            //
        }
        */
      /*
      toast({
        title: `Opening ${platformNameMap[destinationPlatform]} Now!`,
        description: 'Tap Done ↖️ or swipe ⬇️ to close!',
        size: ['sm', 'md', 'md', 'md'],
        status: 'success',
        duration: 10000,
        isClosable: true,
        position: 'top'
      });
      */
        break;
      default:
        //
    }

  }

  const ActionButtons = () => {

    if (loading || !inputUrl) {
      return (
        <Box>
          <BeatLoader/>
        </Box>
      );
    } else {
      return (
        <Box>
          <IconButton
            boxSize={['35px', '35px', '35px', '35px']}
            aria-label="icon"
            variant="ghost"
            color={textColor}
            onClick={() => ButtonHandler('copy')}
            icon={<ExternalLinkIcon boxSize={['25px', '35px', '35px', '35px']} />}
          >
          </IconButton>
          <IconButton
            mr={[1, 2, 2, 2]}
            ml={[1, 2, 2, 1]}
            boxSize={['35px', '35px', '35px', '35px']}
            aria-label="icon"
            variant="ghost"
            color={textColor}
            onClick={() => ButtonHandler('play')}
            icon={<ChevronRightIcon boxSize={['25px', '35px', '35px', '35px']} />}
          >
          </IconButton>
        </Box>
      );
    }
  }

  return (
      <Box
        id={id}
        backgroundColor={backgroundColor}
        borderRadius={10}
        w={['272px', '303px', '303px', '303px']}

        p={['2px', '2px', '2px', '2px']}
        //onClick={handleBadgeClick}
        //border={`.5px solid ${textColor}`}
        boxShadow={'xs'}
        //hidden={multiPlatformState && multiPlatformState[destinationPlatform] ? false : true && true}
        hidden={multiPlatformState && multiPlatformState[destinationPlatform] ? false : true && true}
      >
        <HStack spacing={['1', '1', '2', '2']} justifyContent={'space-evenly'}>
          <Box borderRadius={'10'} boxSize={['35px', '45px', '45px', '45px']} backgroundColor={iconBgColor} >
            <Icon 
              p={1}
              as={platformIcon} 
              boxSize={['35px', '45px', '45px', '45px']} 
              color={iconColor}/>
          </Box>
          <Text
            fontSize={['md', 'md', 'md', 'md']}
            color={textColor}
            pl={1}
          >
            {platformName}
          </Text>
          <Spacer/>
          <ActionButtons/>
        </HStack>
      </Box>
  )
}

export default PlatformBadge;
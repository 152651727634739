import axios, { AxiosResponse } from "axios";

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const getConvertedLinksWithRetry = async (requestUrl: string) => {
    let response: AxiosResponse;
    let count = 1;
    const retryCount = 30;

    do {
        if (count !== 1)
            await delay(count * 500);
        response = await axios.get(requestUrl, { timeout: 10000 })
        count++
    // Retry logic if response status is 202
    } while (response.status === 202 && count < retryCount);

    return response.data;
};
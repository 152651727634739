import ReactGA from 'react-ga4';

export const initGA = (): void => {
  ReactGA.initialize('G-BJ9W2X0HEJ');
};

export const logEvent = (category: string, action: string, label?: string): void => {
  ReactGA.event({ category, action, label });
};

export const logPageView = (pageName: string): void => {
  ReactGA.send({ hitType: "pageview", page: pageName });
};


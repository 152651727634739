import { Box, Heading, Input, InputGroup, InputLeftElement, VStack } from '@chakra-ui/react';
import React, { useState, useContext, useRef, useEffect } from 'react';
import { DataContext, LinkMetaData, MultiPlatformBadgeState } from './DataContext';
import { linkValidation, validSources } from './linkValidation';
import axios from 'axios';
import { SiSpotify, SiTidal } from 'react-icons/si'
import { SiApplemusic } from 'react-icons/si'
import { ResponsiveValue } from '@chakra-ui/react';
import { getConvertedLinksWithRetry } from './getConvertedLinksWithRetry';
import { BeatLoader } from 'react-spinners';

interface DynamicIframeProps {
  topIconColor?: string;
  textColor?: string;
  inputBorderColor?: string;
  baseWidth: string;
}

const DynamicIframe: React.FC<DynamicIframeProps> = ({topIconColor, textColor, inputBorderColor, baseWidth}) => {
  //const toast = useToast();
  const [iframeSrc, setIframeSrc] = useState<string>("");
  const [displayValue, setDisplayValue] = useState<string>("");
  const [iframeHeight, setiframeHeight] = useState<string>("0");
  const [hideInputBox, setHideInputBox] = useState<boolean>(false);
  const [spinner, setSpinner] = useState<boolean>(false);
  const [musicInfo, setMusicInfo] = useState<MusicInfo>();
  const { setLoading, inputUrl, setInputUrl, linkMetaData, setLinkMetaData, setMultiPlatformState, multiPlatformState, setAlbumColors, setHideWelcomeElements} = useContext(DataContext) || {};
  //const errorToastId = 'errorToastId'
  const inputRef = useRef<HTMLInputElement>(null);

  interface MusicInfo {
    album: string,
    artist: string,
    track: string,
    imgLink: string
  }

  useEffect( () => {
    const url = new URL(window.location.href);
    //

    switch (url.host) {
      //PROD
      case "shortcut.linkmytunes.com":
        window.location.href = process.env.REACT_APP_IOS_SHORTCUT_LINK ?? "linkmytunes.com";
        break;
      //DEV
      case "shortcut.dev.linkmytunes.com":
        window.location.href = process.env.REACT_APP_DEV_IOS_SHORTCUT_LINK ?? "dev.linkmytunes.com";
        break;
      default:
    }

    if (url.search && url.searchParams.has("link")) {
      let encodedUrl = url.searchParams.get("link") ?? "";
      inputReset();
      //disable/hide the input box
      setDisplayValue("Copied");
      setHideInputBox(false);
      setHideWelcomeElements && setHideWelcomeElements(true);
      handleChange(decodeURI(encodedUrl));
    } else {
      setHideWelcomeElements && setHideWelcomeElements(false);
    }

  // [] ensures running once, removing runs multiple and [var] runs according to var changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const blurInputBox = () => {
    if (inputRef.current)
      inputRef.current.blur();
  }

  let parseInputUrl = async (inputUrl: string) => {

    //after validation
    const parsedUrl = new URL(inputUrl);
    let splitPathNameBySlash = parsedUrl.pathname.split('/');

    let linkMetaData: LinkMetaData = {
      sourcePlatform: validSources[parsedUrl.hostname],
      resourceType: '',
      resourceId: '',
      destinationPlatform: '',
    };
    
    let embedSrc = "";
    switch (linkMetaData.sourcePlatform) {
      case "spotify":
        linkMetaData.resourceType = splitPathNameBySlash[1];
        linkMetaData.resourceId = splitPathNameBySlash[2];
        embedSrc = `https://open.spotify.com/embed${parsedUrl.pathname}`;
        break;
      case "apple":
        linkMetaData.resourceType = parsedUrl.search ? 'track' : 'album';
        linkMetaData.resourceId = parsedUrl.search ? parsedUrl.searchParams.get('i') ?? '' : parsedUrl.pathname.split('/')[4] 
        embedSrc = `https://embed.${parsedUrl.hostname}${parsedUrl.pathname}${parsedUrl.search}`;
        break;
      case "tidal":
        //
        linkMetaData.resourceType = splitPathNameBySlash[1];
        linkMetaData.resourceId = splitPathNameBySlash[2];

        embedSrc = `https://embed.${parsedUrl.hostname}/${linkMetaData.resourceType}s/${linkMetaData.resourceId}?layout=classic&disableAnalytics=true`;
        //
            //src="https://embed.tidal.com/albums/339489669?layout=gridify&disableAnalytics=true" 
        break;
      default:
        throw new Error("Unsupported platform");
    }


    let height;
    switch (linkMetaData.resourceType) {
      case "track":
        height = "150";
        break;
      case "album":
        height = "450";
        break;
      default:
        height = "0";
    }
    setiframeHeight(height);
    setIframeSrc(embedSrc);

    //

    setLinkMetaData && setLinkMetaData(linkMetaData);

    await getMusicInfo(linkMetaData);
    //

  }

  const AlbumCard = () => {

    //Don't render album card if multiPlatformState and inputUrl are not set
    if (!multiPlatformState || (!inputUrl))
      return null;

    let getStateItem = (itemType: string) => {
      switch(itemType) {
        case 'img':
          return musicInfo?.imgLink;
        case 'track':
          return musicInfo?.track;
        case 'album':
          return musicInfo?.album;
        case 'artist':
          return musicInfo?.artist;
      }
    }

    //if (loading) {
    if (spinner) {
      return (
          <BeatLoader/>
      );
    } else {
      return (
        <VStack w={'85%'} justify={'space-between'} spacing={1}>
          <Box 
            boxSize={['272px', '272px', '272px', '272px']}
            //border={'1px solid #23001e'}
            borderRadius={10}
            backgroundImage={`url(${getStateItem('img')})`} 
            backgroundPosition={'center'}
            backgroundRepeat={'no-repeat'}
            backgroundSize={'cover'}
            boxShadow={'lg'}
          >
          </Box>
          <Box 
            bgColor={'#000000000'}
            borderBottomRadius={10}
            w={baseWidth}
            pt={1}
          >
            <Heading fontSize={['lg', 'xl', 'xl', '2xl']} textAlign={'center'} textColor={textColor} >
              {getStateItem('track') ?? getStateItem('album')}
            </Heading>
            <Heading fontSize={['sm', 'md', 'md', 'md']} textAlign={'center'} textColor={textColor} >
              {getStateItem('artist')}
            </Heading>
          </Box>
        </VStack>
      );
    }
  }

  // eslint-disable-next-line
  const urlEmbedFactory = () => {
    let width = "90%";
    switch (linkMetaData?.sourcePlatform) {
      case "spotify":
        return (
          <iframe
            title='spotify'
            src={iframeSrc}
            width={width}
            height={iframeHeight}
            frameBorder="0"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        );
      case "apple":
        return (
          <iframe
            title='apple'
            src={iframeSrc}
            scrolling="no"
            allow="autoplay *; encrypted-media *;"
            height={iframeHeight}
            style={{ width: width, overflow: "hidden", background: "transparent" }}
            sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
          ></iframe>
        );
      case "tidal":
        return (
          <iframe 
            title='tidal'
            src={iframeSrc} 
            height={iframeHeight}
            allow="encrypted-media" 
            frameBorder="0" 
            style={{width: width, borderRadius: '10px', padding: 20, backgroundColor: '#101012'}}
              
          ></iframe>
        );
      default:
        return null;
    }
  };

  /*
  const handleOnChange = async (event: any) => {
    if (!hideInputBox) {
      let url = event.target.value as string;
      await handleChange(url);
    }
  }
  */

  const handleChange = async (url: string) => {
    try {

      //used to dismiss the keyboard directly after pasting on mobile
      //blurInputBox();
      url = await linkValidation(url);
      setInputUrl && setInputUrl(url);
      setHideWelcomeElements && setHideWelcomeElements(true);
      
      //show loading component here
      //setLoading && setLoading(true);
      await parseInputUrl(url);
      //show content after loaded
      //setLoading && setLoading(false);

      /*
      toast({
        title: 'Link Found!',
        description: 'Choose a Platform Below ⬇️',
        size: ['sm', 'md', 'md', 'md'],
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top'
      });
      */

    } catch (error) {
      console.log(error)
      setHideInputBox(false);

      /*
      if (!toast.isActive(errorToastId)) {
        toast({
          id: errorToastId,
          title: 'Invalid Link!',
          description: `Copy or Paste a valid Spotify, Apple or Tidal Link`,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top'
        });
      }
      */
      inputReset();
    }


  };

  // Get track information icluding artist, albumn, artwork and colors
  const getMusicInfo = async (linkMetaData: LinkMetaData) => {

    let apiDomain = process.env.REACT_APP_ENV === 'PROD' ? process.env.REACT_APP_MLC_API_URL : process.env.REACT_APP_MLC_API_URL_LOCAL

    //let validatedLinkMetaData: LinkMetaData = await linkMetaDataValidation(linkMetaData);
    let fuckingEmptyObj: LinkMetaData = {
      sourcePlatform: '',
      resourceType: '',
      resourceId: '',
      destinationPlatform: ''
    }

    let validatedLinkMetaData = linkMetaData ?? fuckingEmptyObj;

    ////

    let musicInfoRequestUrl = `${apiDomain}/link-data/musicInfo?sourcePlatform=${validatedLinkMetaData.sourcePlatform}&resourceType=${validatedLinkMetaData.resourceType}&resourceId=${validatedLinkMetaData.resourceId}`;

    //global platformBadge state disable
    //setDisableFetch && setDisableFetch(true);
    //badgeState[platformName ?? ''] = true;
    //setMultiPlatformState(badgeState)

    interface MusicInfoResponseData {
      linkDataId: string,
      musicInfo: {
        track: string,
        artist: string,
        album: string,
        albumColors: string[],
        imgLink: string,
      }
    }

    // Initial request to get music info, also kicks off async conversion
    //HERE
    setSpinner(true);
    let musicInfoResponse = await axios.get(musicInfoRequestUrl, { timeout: 10000 });
    setSpinner(false);

    const musicInfoResponseData: MusicInfoResponseData = musicInfoResponse.data;
    
    const convertedLinksRequestUrl = `${apiDomain}/link-data/convertedLinks/${musicInfoResponseData.linkDataId}`;

    const getAndSetConvertedLinks = async (convertedLinksRequestUrl: string) => {

      setLoading && setLoading(true);
      const links: { linkMap: { [key: string]: string} } = await getConvertedLinksWithRetry(convertedLinksRequestUrl);
      setLoading && setLoading(false);

      if (!links)
        return null;

      let multiPlatformBadgeState = Object.entries(links.linkMap).reduce((acc: MultiPlatformBadgeState, [key, value]) => {
        acc[key] = {
          link: value,
          hideBadge: false,
        };
        return acc;
      }, {} as MultiPlatformBadgeState);

      //
      setMultiPlatformState && setMultiPlatformState(multiPlatformBadgeState)
    }; 

    //Lesson, you have to use set State to ensure react is triggered to re-render...
    //Updating state of this platform without affecting other platforms

    let musicInfo: MusicInfo = {
      track: musicInfoResponseData.musicInfo.track,
      album: musicInfoResponseData.musicInfo.album,
      artist: musicInfoResponseData.musicInfo.artist,
      imgLink: musicInfoResponseData.musicInfo.imgLink
    }
    setMusicInfo(musicInfo);

    setAlbumColors && setAlbumColors(musicInfoResponseData.musicInfo.albumColors);
    getAndSetConvertedLinks(convertedLinksRequestUrl);
  }

  // Hide input box when image loads...

  const pasteFromClipboard = async (event: any) => {
    if (inputUrl && inputUrl !== "") {
      inputReset()
    } else {
      //used to dismiss the keyboard directly
      blurInputBox();
      try {
      //readText would not ready 'text/uri-list' properly...
      const items = await navigator.clipboard.read();
      let textContent: string = "";
      console.log(items);
      for (const item of items) {
        if (item.types.includes('text/plain')) {
          const text = await item.getType('text/plain');
          textContent = await text.text();
          console.log(textContent);
        } else if (item.types.includes('text/uri-list')) {
          const text = await item.getType('text/uri-list');
          textContent = await text.text();
        }
      }
      await handleChange(textContent);

      } catch (error) {
        console.log('Paste denied');
      }
    }
  }

  const inputReset = () => {
    const url = new URL(window.location.href);

    setInputUrl && setInputUrl("") 
    //used to dismiss the keyboard directly after pasting on mobile
    blurInputBox();
    setHideWelcomeElements && setHideWelcomeElements(false);
    setDisplayValue("")
    setMultiPlatformState && setMultiPlatformState({});
    setIframeSrc("");
    linkMetaData && (linkMetaData.sourcePlatform = '')
    setLoading && setLoading(false);
    setAlbumColors && setAlbumColors([]);
    url.searchParams.delete('link');

    let blankMusicInfo: MusicInfo = {
      track: '',
      album: '',
      artist: '',
      imgLink: ''
    }
    setMusicInfo(blankMusicInfo);
  }

  const InputGroupLogic = () => {
    /*
      - On initial view, no left or right icon/elements, just placeholder, "Paste Link"
      - After validation is done, select respective platform icon for the left
      - After loading is done, and conversion was a success show checkmark, otherwise show error toast and reset input box 
    */
   let inputBoxWidth = ['70px', '70px', '70px', '70px'];
   let hideInputLeftIcon = true;
   let textAlign: ResponsiveValue<'left' | 'center' | 'right' | 'justify'> = 'center';
   let paddingRight = '0px';
   let placeHolder = "Paste";

    if (inputUrl) {
      inputBoxWidth = ['85px', '85px', '85px', '85px'];
      hideInputLeftIcon = false;
      textAlign = 'right';
      paddingRight = '6px';
      placeHolder = 'Pasted'
    }

    //determine platform icon
    const getPlatformIcon = () => {
      if (hideInputLeftIcon)
        return null;

      switch (linkMetaData?.sourcePlatform) {
        case 'spotify':
          return <SiSpotify size={'20px'} color={topIconColor}/>
        case 'apple':
          return <SiApplemusic size={'20px'} color={topIconColor}/>
        case 'tidal':
          return <SiTidal size={'20px'} color={topIconColor}/>
        default:
      }
      //set display value here on the input box, something other than ugly URL
      //setDisplayValue(`${platformNameMap[linkMetaData?.sourcePlatform ?? '']} link Pasted`)
    }


    return (
      <InputGroup justifyContent={'center'} w={inputBoxWidth}>
        <InputLeftElement boxSize={'20px'} pointerEvents='none' hidden={hideInputLeftIcon} pl={'5px'} pt={'5px'}>
          {getPlatformIcon()}
        </InputLeftElement>
        <Input
          //boxSize={'30px'}
          ref={inputRef} 
          //w={'35%'}
          h={'25px'}
          placeholder={placeHolder}
          _placeholder={{ color: inputBorderColor }}
          textColor={inputBorderColor}
          pr={paddingRight}
          pl={0}
          textAlign={textAlign}
          //Don't set to 'sm', will cause annoying zoom on mobile
          fontSize={['md', 'md', 'md', 'md']}
          variant="outline"
          borderRadius={5}
          borderColor={inputBorderColor}
          focusBorderColor={inputBorderColor}
          value={displayValue}
          //onChange={handleOnChange}
          hidden={hideInputBox}
          //onClick={inputReset}
          onClick={pasteFromClipboard}
          border='1px'
          //This right here is gold!
          readOnly
        />
      </InputGroup>
    )
  }

  return (
    <VStack w={'100%'} spacing={[2, 2, 2, 2]} mt={[2, 1, 2, 2]} mb={[1, 1, 2, 2]}>
      {InputGroupLogic()}
      <AlbumCard/>
      {/** 
      <HStack hidden={!iframeSrc ? true : false}>
        <Badge colorScheme='green' borderRadius={5}>
          <Icon as={ArrowDownIcon} boxSize={'25px'} />
          Choose a Platform Below to Convert
          <Icon as={ArrowDownIcon} boxSize={'25px'} />
        </Badge>
      </HStack>
      */}
    </VStack>
  );
};

export default DynamicIframe;

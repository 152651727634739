import Joi from "joi";
import { LinkMetaData } from "./DataContext";

export const platformNameMap: { [key: string]: string } = {
  'spotify': 'Spotify',
  'apple': 'Apple Music',
  'tidal': 'Tidal'
}

export const validSources: { [key: string]: string } = {
  'open.spotify.com': 'spotify',
  'music.apple.com': 'apple',
  'tidal.com': 'tidal'
}
const validResourceTypes = ['track', 'album'];

let urlSchema = Joi
    .string()
    .uri()
    .required()
    .custom((value, helpers) => {
        let url = new URL(value);
        if (!Object.keys(validSources).includes(url.hostname)) {
            throw new Error("Invalid Domain");
        }
        return value;
    }, 'Domain Validation');


export let linkValidation = async (url: string): Promise<string> => {

    if (url) {
        return await urlSchema.validateAsync(url);
    } else {
        throw new Error("Url cannot be empty");
    }
}

let linkMetaDataSchema = Joi.object<LinkMetaData>({
  sourcePlatform: Joi.string().valid(...Object.values(validSources)).required(),
  destinationPlatform: Joi.string().valid(...Object.values(validSources)),
  resourceType: Joi.string().valid(...validResourceTypes).required(),
  resourceId: Joi.string().alphanum().max(30).required()
});

export let linkMetaDataValidation = async (linkMetaData: LinkMetaData | undefined) => {

    if (!linkMetaData)
        throw new Error("Query parameters must be provided");

    let validatedObj = await linkMetaDataSchema.validateAsync(linkMetaData);
    if (validatedObj.destinationPlatform === validatedObj.sourcePlatform)
        throw new Error("Source and Destination platforms must be different");

    return validatedObj;
}

import { useContext, useEffect, useState } from 'react'
import {
  ChakraProvider,
  VStack,
  Heading,
  HStack,
  Spacer,
  Divider,
  Text,
  Box,
  Alert,
  AlertDescription,
  AlertIcon,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react'
import DynamicIframe from './DynamicIframe'
import { SiSpotify, SiTidal, SiYoutubemusic } from 'react-icons/si'
import { SiApplemusic } from 'react-icons/si'
import PlatformBadge from './PlatformBadge'
import { DataContext} from './DataContext'
import { initGA, logPageView } from './analytics'
import AdSenseScript from './AdSenseScript'
import { SiAmazonmusic } from "react-icons/si";
import { getBestTextColor } from './getBestTextColor'
import { BeatLoader } from 'react-spinners'
import './styles.css';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";


const App = () => {
  
  useEffect(() => {
    initGA();
    logPageView('/index'); // Log initial page view

    const url = new URL(window.location.href);
    
    if (url.search && url.searchParams.has("context")) {
      const clientContext = url.searchParams.get("context") ?? "";
      setClientContext(clientContext); 
      setBaseWidth('300px');
    }

  }, []);
  let domain = process.env.REACT_APP_ENV === 'PROD' ? 'https://linkmytunes.com' : 'https://test.linkmytunes.com'
  const { albumColors, loading, hideWelcomeElements} = useContext(DataContext) || {};
  const [clientContext, setClientContext] = useState<string>("");
  const [baseWidth, setBaseWidth] = useState<string>("256px");


  let albumColor1 = (albumColors && albumColors[0]) ?? '#141414'
  let albumColor2 = (albumColors && albumColors[1]) ?? '#6A6A6A'

  let textColorTop = getBestTextColor(albumColor1 ?? '#b9baa3')
  let textColorBt = getBestTextColor(albumColor2 ?? '#b9baa3')

  const className = (classSuffix: string) => {
    if (clientContext && clientContext === 'ios') {
      return `ios-${classSuffix}`;
    }
    if (clientContext && clientContext === 'ios-share-sheet') {
      return `ios-share-sheet-${classSuffix}`;
    }
  }

  const images = [
    {
      original: `${domain}/showcase/img0.png`,
    },                                         
    {                                          
      original: `${domain}/showcase/img1.png`,
    },                                         
    {                                          
      original: `${domain}/showcase/img2.png`,
    },                                         
    {                                          
      original: `${domain}/showcase/img3.png`,
    },                                        
    {                                          
      original: `${domain}/showcase/img4.png`,
    },                                        
    {                                        
      original: `${domain}/showcase/img5.png`,
    },                                      
    {                                      
      original: `${domain}/showcase/img6.png`,
    },                                    
    {                                    
      original: `${domain}/showcase/img7.png`,
    },                                  
    {                                  
      original: `${domain}/showcase/img8.png`,
    },                                
    {                                
      original: `${domain}/showcase/img9.png`,
    },                              
    {                              
      original: `${domain}/showcase/img10.png`,
    },                            
    {                            
      original: `${domain}/showcase/img11.png`,
    }
  ];

  const WelcomeAlert = () => {
      return (
        <Alert
          hidden={hideWelcomeElements}
          variant='subtle'
          flexDirection='row'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          height={'85px'}
          w={baseWidth}
          bgColor={'#7F7F7F33'}
          textColor={textColorTop}
          borderRadius={10}
          mt={2}
          p={1}
        >
          <AlertIcon boxSize='20px' mr={4} color={'#b9baa3'} />
          <AlertDescription maxWidth='sm' textAlign={'left'} fontSize='xs'>
            <UnorderedList lineHeight={'20px'}>
              <ListItem>
                Launch from the share menu.
              </ListItem>
              <ListItem>
                Or directly paste your link above.
              </ListItem>
              <ListItem>
                Tap the paste box to reset.
              </ListItem>
              <ListItem>
                Check out the samples below.
              </ListItem>
            </UnorderedList>
          </AlertDescription>
        </Alert>
      );
  }

  const Showcase = () => {
    if (!hideWelcomeElements) {
      return (
        <Box w={baseWidth} borderRadius={10} borderWidth={'1px'} borderColor={textColorTop} mt={3}>
          <ImageGallery startIndex={Math.floor(Math.random() * images.length)} items={images} showThumbnails={false} autoPlay={true} showPlayButton={false} showFullscreenButton={false}/>
        </Box>
      );
    } else {
      return null;
    }
  }

  return (
    <ChakraProvider resetCSS>
      <AdSenseScript/>
      <VStack
        w={"100vw"}
        minH={"100dvh"}
        bgGradient={`linear(to-b, ${albumColor1}, ${albumColor2})`}
        justify={'center'}
        spacing={0}
        className={className('parent-vstack')}
        overflow={'hidden'}
      >
            <Heading fontSize={['3xl', '3xl', '3xl', '3xl']} color={textColorTop}>
              Link My Tunes
            </Heading>
            <Text fontSize={['xs', 'xs', 'md', 'md']} textAlign={'center'} color={textColorTop} >
              Convert | Share | Open | Music Links
            </Text>
            <Divider borderColor={textColorTop} w={['272px', '272px', '272px', '272px']}/>
            <DynamicIframe inputBorderColor={textColorTop} topIconColor={textColorTop} textColor={textColorTop} baseWidth={baseWidth}/>
            <WelcomeAlert/>
            <Showcase/>
            <BeatLoader loading={loading} />
            <VStack spacing={[1.5, 1.5, 1.5, 1.5]}
              w={'100%'}
              minH={'100%'}
              pt={'4px'}
            >
              <PlatformBadge id='1' platformName='Spotify' platformIcon={SiSpotify} textColor={textColorTop} iconColor='#1ed760' iconBgColor='black' backgroundColor='#7F7F7F33'/>
              <PlatformBadge id='2' platformName='Apple Music' platformIcon={SiApplemusic} textColor={textColorTop} iconBgColor="white" iconColor='#d60017' backgroundColor='#7F7F7F33'/>
              <PlatformBadge id='3' platformName='Tidal' platformIcon={SiTidal} textColor={textColorTop} iconColor='white' iconBgColor='black' backgroundColor='#7F7F7F33'/>
              <PlatformBadge id='4' platformName='Youtube Music' platformIcon={SiYoutubemusic} textColor={textColorTop} iconBgColor='white' iconColor='#d60017' backgroundColor='#7F7F7F33'/>
              <PlatformBadge id='5' platformName='Amazon Music' platformIcon={SiAmazonmusic} textColor={textColorTop} iconBgColor='#25D1DA' iconColor='black' backgroundColor='#7F7F7F33'/>
            </VStack>
            <Spacer/>
            <HStack w={'100%'} justify={'center'}>
              <Text fontSize='2xs' color={textColorBt}>© 2024 linkmytunes.com. All rights reserved. {process.env.REACT_APP_VERSION ?? "v0.0.0"}</Text>
            </HStack>
          </VStack>
    </ChakraProvider>
)}

export default App